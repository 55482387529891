header {
  height: 80vh;
  padding-top: 2rem;
  overflow: hidden;
  padding-bottom: 5.7rem;
}

.logo {
  position: relative;
  width: 4rem;
  left: calc(50% - 34.8rem);
  top: -1rem;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

/* ==============CTA Styles================ */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ==============Social Button Styles================ */
.social__Icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3.6rem;
}

.social__Icons::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ==============Header Image================ */
.me {
  width: 22rem;
  height: 32rem;
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 2rem;
  border-radius: 12rem 12rem 0rem 0rem;
  overflow: hidden;
  padding: 5rem 1.5rem 1.5rem 1.5rem;
}

/* ==============Scroll Down================ */
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 10rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/* =============== Media Queries(Medium Devices)=========== */
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
    padding-bottom: 13rem;
  }

  .logo {
    display: none;
  }

  .me {
    width: 40rem;
    height: 100rem;
    left: calc(50% - 20rem);
    margin-top: 0rem;
    border-radius: 12rem 12rem 0rem 0rem;
    overflow: hidden;
    padding: 5rem 1.5rem 1.5rem 1.5rem;
  }

  .scroll__down {
    display: none;
  }

  .social__Icons {
    display: none;
  }
}

/* =============== Media Queries(Small Devices)=========== */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
    padding-top: 0.2rem;
    padding-bottom: 1.5rem;
  }

  .logo {
    display: none;
  }

  .social__Icons,
  .scroll__down {
    display: none;
  }

  .me {
    width: 20rem;
    height: 50rem;
    left: calc(50% - 11.8rem);
    margin-top: 0.5rem;
  }
}
