.about__container {
  display: grid;
  grid-template-columns: 35% 50%;
  padding-top: 2rem;
  padding-bottom: 6rem;
  gap: 15%;
}

.about__me {
  width: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-pref),
    transparent
  );
  display: grid;
  place-items: center;
}

.text {
  padding-top: 8rem;
}

.about__me-image {
  border-radius: 2rem;
  overflow: hidden;
  transform: rotate(15deg);
  transition: var(--transition);
}

.about__me-image:hover {
  transform: rotate(0);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.about__card {
  background-color: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: var(--transition);
}

.about__card:hover {
  background-color: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.about__icon {
  color: var(--color-primary);
  font-size: 1.4rem;
  margin-bottom: rem;
}

.about__card h5 {
  font-size: 0.95rem;
}

.about__card small {
  font-size: 0.7rem;
  color: var(--color-light);
}

.about__content p {
  margin: 2rem 0 2.6rem;
  color: var(--color-light);
}

/* =============== Media Queries(Medium Devices)=========== */
@media screen and (max-width: 1024px) {
  .about__container {
    grid-template-columns: 1fr;
    gap: 0;

    padding-bottom: 7rem;
  }

  .about__me {
    width: 50%;
    margin: 2rem auto 4rem;
  }

  .about__content p {
    margin: 1rem 0 1.5rem;
  }

  .text {
    padding-top: 1rem;
  }
}

/* =============== Media Queries(Small Devices)=========== */
@media screen and (max-width: 600px) {
  .about__container {
    padding-bottom: 0rem;
    padding-top: 0rem;
  }

  h2 {
    padding-bottom: 0;
  }

  .about__me {
    width: 50%;
    margin: 0 auto 3rem;
  }

  .text {
    padding-top: 0;
  }

  .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about__content {
    text-align: center;
  }

  .about__content p {
    margin: 1.5rem 0;
  }
}
